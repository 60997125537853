import Iconify from 'src/components/Iconify';
import DashboardIcon from '@mui/icons-material/Dashboard';
// import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
// import RoomPreferencesIcon from '@mui/icons-material/RoomPreferences';
import HomeRepairServiceIcon from '@mui/icons-material/HomeRepairService';
import CategoryIcon from '@mui/icons-material/Category';
import InventoryIcon from '@mui/icons-material/Inventory';
// import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
// import GroupIcon from '@mui/icons-material/Group';
// import Diversity3Icon from '@mui/icons-material/Diversity3';
// import DiscountIcon from '@mui/icons-material/Discount';
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';

const isAdmin = [
  {
    title: 'dashboard',
    path: '/dashboard/app',
    icon: <Iconify icon="material-symbols:dashboard" width={24} height={24} />
  },
  {
    title: 'users',
    path: '/dashboard/users',
    icon: <Iconify icon="fa:users" width={24} height={24} />,
    subItem: [
      {
        item: [
          {
            title: 'Users',
            path: '/dashboard/users',
            icon: <Iconify icon="fa-solid:users-cog" width={24} height={24} />
          },
          {
            title: 'Users Roles',
            path: '/dashboard/user-role',
            icon: <Iconify icon="fa6-solid:user-gear" width={24} height={24} />
          }
        ]
      }
    ]
  },
  {
    title: 'Blogs',
    path: '/dashboard/blogs',
    icon: <Iconify icon="fa6-solid:blog" width={24} height={24} />,
    subItem: [
      {
        item: [
          {
            title: 'Blogs',
            path: '/dashboard/blogs',
            icon: <Iconify icon="fa6-solid:blog" width={24} height={24} />
          },
          {
            title: 'Blog Categories',
            path: '/dashboard/blog-categories',
            icon: <Iconify icon="carbon:blog" width={24} height={24} />
          }
        ]
      }
    ]
  },
  // {
  //   title: 'Faqs',
  //   path: '/dashboard/pages/faqs',
  //   icon: <Iconify icon="mdi:faq" width={24} height={24} />
  // }
  // {
  //   title: 'Reviews',
  //   path: '/dashboard/reviews',
  //   icon: getIcon('ic:baseline-reviews')
  // }
];

const isUser = [
  {
    title: 'dashboard',
    path: '/dashboard/app',
    icon: <DashboardIcon />
  },
  // {
  //   title: 'Services',
  //   path: '/dashboard/packages',
  //   icon: <HomeRepairServiceIcon />,
  //   subItem: [
  //     {
  //       item: [
  //         {
  //           title: 'Services',
  //           path: '/dashboard/services',
  //           icon: <HomeRepairServiceIcon />
  //         },
  //         {
  //           title: 'Service Categories',
  //           path: '/dashboard/package-category',
  //           icon: <CategoryIcon />
  //         },
  //         {
  //           title: 'Packages',
  //           path: '/dashboard/packages',
  //           icon: <InventoryIcon />
  //         }
  //       ]
  //     }
  //   ]
  // },
  {
    title: 'Blogs',
    path: '/dashboard/blogs',
    icon: <DocumentScannerIcon />
  }
];
// Check the user role

export default function sidebarConfig(role) {
  return role == '1' ? isAdmin : isUser;
}
