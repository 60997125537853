// material
import { Box, Grid, Container, Typography } from '@mui/material';
// components
import Page from '../components/Page';
import { AppNewUsers, TotalBlogs } from '../sections/@dashboard/app';
//
import { AppReviews } from '../sections/@dashboard/app/ForOtherUsers/AppReviews';
import { AppBlogs } from '../sections/@dashboard/app/ForOtherUsers/AppBlogs';
//
import withRole from 'src/components/HOC/withRole';
// ----------------------------------------------------------------------
function DashboardApp(props) {
  // Check User Role
  const user = props.user;
  const userRole = user?.user_role;

  return (
    <Page title="Dashboard">
      <Container maxWidth="xl">
        <Box sx={{ pb: 5 }}>
          <Typography variant="h4">Welcome back {user?.name ?? 'Admin'}</Typography>
        </Box>
        <Grid container spacing={3}>
          {userRole == '1' ? (
            <>
              <Grid item xs={12} sm={6} md={3}>
                <AppNewUsers />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <TotalBlogs />
              </Grid>
            </>
          ) : (
            <>
              {/* For Users */}
              <Grid item xs={12} sm={6} md={3}>
                <AppReviews />
              </Grid>
              {/* <Grid item xs={12} sm={6} md={3}>
                <AppFaqs />
              </Grid> */}
              <Grid item xs={12} sm={6} md={3}>
                <AppBlogs />
              </Grid>
              {/*  */}
            </>
          )}
        </Grid>
      </Container>
    </Page>
  );
}

export default withRole(DashboardApp);
