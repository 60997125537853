import { Navigate, useRoutes, Outlet } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
//
import Login from './pages/Login';
import DashboardApp from './pages/DashboardApp';
// Blog Components
import Blogs from './pages/Blogs/Blogs';
import CreateBlogForm from './pages/Blogs/components/CreateBlogForm';
import EditBlogForm from './pages/Blogs/components/EditBlogForm';
// Blog Categories
import BlogCategories from './pages/BlogCategories';
import CreateBlogCategory from './pages/BlogCategories/components/CreateBlogCategory';
import EditBlogCategory from './pages/BlogCategories/components/EditBlogCategory';
// User
import AllUsers from './pages/User/AllUsers';
import CreateUser from './pages/User/components/CreateUser';
import EditUser from './pages/User/components/EditUser';
import UserProfile from './pages/User/components/UserProfile';
import EditUserProfile from './pages/User/components/EditUserProfile';
// User Role
import UserRoles from './pages/UserRole/UserRoles';
import AddUserRoles from './pages/UserRole/components/AddUserRole';
import EditUserRole from './pages/UserRole/components/EditUserRole';
// // FAQs
import FAQ from './pages/FAQ/FAQ';
// import CreateFaqForm from './pages/FAQ/components/CreateFaqForm';
// import EditFaqForm from './pages/FAQ/components/EditFaqForm';
// // Pages
// import HomePage from './pages/AddPages/Home/HomePage';
// import FaqsPage from './pages/AddPages/Faqs/Faqs';
// import AboutPage from './pages/AddPages/About/About';
// import ContactPage from './pages/AddPages/Contact/Contact';
// import PrivacyPolicy from './pages/AddPages/PrivacyPolicy/PrivacyPolicy';
// import RefundPolicy from './pages/AddPages/RefundPolicy/RefundPolicy';
// import EditMainPage from './pages/AddPages/editPage';
// // Home Page Sections
// import EditTopSection from './pages/AddPages/Home/components/TopSection/EditTopSection';
// import EditPredominantlySection from './pages/AddPages/Home/components/PredominantlyUsedServices/EditPredominantlySection';
// import EditPowerfulServiceSection from './pages/AddPages/Home/components/PowerfulServices/EditPowerfulServices';
// import EditTargetedViews from './pages/AddPages/Home/components/TargetedViews/EditTargetedViews';
// import EditHowToBuySection from './pages/AddPages/Home/components/HowToBuy/EditHowToBuySection';
// import EditWhyUsSection from './pages/AddPages/Home/components/WhyUs/EditWhyUsSection';
// import EditProudlyDeliveredServices from './pages/AddPages/Home/components/ProudlyDelivered/EditProudlyDeliveredServices';
// // About Page Sections
// import EditAboutTopSection from './pages/AddPages/About/components/TopSection/EditAboutTopSection';
// import EditMissionSection from './pages/AddPages/About/components/OurMission/EditMissionSection';
// import EditAboutValuesSection from './pages/AddPages/About/components/AboutValues/EditAboutValuesSection';
// // Add Privacy Policy
// import CreatePrivacy from './pages/AddPages/PrivacyPolicy/components/CreatePrivacy';
// import UpdatePrivacy from './pages/AddPages/PrivacyPolicy/components/UpdatePrivacy';
// // Refund Policy
// import UpdateRefundPolicy from './pages/AddPages/RefundPolicy/UpdateRefundPolicy';
// // Reviews
// import Reviews from './pages/Reviews/Reviews';
// import AddReview from './pages/Reviews/components/AddReview';
// import EditReview from './pages/Reviews/components/EditReview';
// // Contact Page Section
// import EditContactTopSection from './pages/AddPages/Contact/components/TopSection/EditContactTopSection';

// ----------------------------------------------------------------------
import NotFound from './pages/Page404';
// Login Protected Route
// import PrivateRoute from './Routes/PrivateRoute';
// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        { path: 'app', element: <DashboardApp /> },
        // Users
        {
          path: 'users',
          element: <Outlet />,
          children: [
            { path: '', element: <AllUsers /> },
            { path: 'create-user', element: <CreateUser /> },
            { path: 'edit-user/:id', element: <EditUser /> }
          ]
        },
        { path: 'user-profile', element: <UserProfile /> },
        { path: 'user-profile/edit', element: <EditUserProfile /> },
        // User Role
        {
          path: 'user-role',
          element: <Outlet />,
          children: [
            { path: '', element: <UserRoles /> },
            { path: 'add-user-role', element: <AddUserRoles /> },
            { path: 'edit-user-role/:id', element: <EditUserRole /> }
          ]
        },
        // Blogs
        {
          path: 'blogs',
          element: <Outlet />,
          children: [
            { path: '', element: <Blogs /> },
            { path: 'add-blog', element: <CreateBlogForm /> },
            { path: 'edit-blog/:slug', element: <EditBlogForm /> }
          ]
        },
        // Blog Categories
        {
          path: 'blog-categories',
          element: <Outlet />,
          children: [
            { path: '', element: <BlogCategories /> },
            { path: 'add-blog-category', element: <CreateBlogCategory /> },
            { path: 'edit-blog-category/:slug', element: <EditBlogCategory /> }
          ]
        },
        // // Reviews
        // {
        //   path: 'reviews',
        //   element: <Outlet />,
        //   children: [
        //     { path: '', element: <Reviews /> },
        //     { path: 'add-review', element: <AddReview /> },
        //     { path: 'edit-review/:id', element: <EditReview /> }
        //   ]
        // },

        // // Faqs
        // { path: 'pages/faqs', element: <FAQ /> },
        // { path: 'pages/faqs/add-faq', element: <CreateFaqForm /> },
        // { path: 'pages/faqs/edit-faq/:id', element: <EditFaqForm /> },
        // // Pages
        // { path: 'pages/home', element: <HomePage /> },
        // { path: 'pages/about', element: <AboutPage /> },
        // { path: 'pages/contact', element: <ContactPage /> },
        // { path: 'pages/faq', element: <FaqsPage /> },
        // { path: 'pages/terms', element: <PrivacyPolicy /> },
        // { path: 'pages/refund-policy', element: <RefundPolicy /> },
        // { path: 'pages/edit-page/:pageId', element: <EditMainPage /> },
        // // Home Page Sections
        // { path: 'pages/home/edit-top-section/:id', element: <EditTopSection /> },
        // {
        //   path: 'pages/home/edit-predominantly-section/:id',
        //   element: <EditPredominantlySection />
        // },
        // {
        //   path: 'pages/home/edit-powerful-section/:id',
        //   element: <EditPowerfulServiceSection />
        // },
        // {
        //   path: 'pages/home/edit-targeted-views-section/:id',
        //   element: <EditTargetedViews />
        // },
        // {
        //   path: 'pages/home/edit-howToBuy-section/:id',
        //   element: <EditHowToBuySection />
        // },
        // {
        //   path: 'pages/home/edit-proudly-delivered-section/:id',
        //   element: <EditProudlyDeliveredServices />
        // },
        // {
        //   path: 'pages/home/edit-why-us-section/:id',
        //   element: <EditWhyUsSection />
        // },

        // // About Page Sections
        // {
        //   path: 'pages/about/edit-top-section/:id',
        //   element: <EditAboutTopSection />
        // },
        // {
        //   path: 'pages/about/edit-mission-section/:id',
        //   element: <EditMissionSection />
        // },
        // {
        //   path: 'pages/about/edit-values-section/:id',
        //   element: <EditAboutValuesSection />
        // },
        // // Contact Page Section
        // {
        //   path: 'pages/contact/edit-top-section/:id',
        //   element: <EditContactTopSection />
        // },
        // // Add Privacy Policy
        // {
        //   path: 'pages/terms/add-term',
        //   element: <CreatePrivacy />
        // },
        // {
        //   path: 'pages/terms/edit-term/:id',
        //   element: <UpdatePrivacy />
        // },
        // // Refund Policy
        // {
        //   path: 'pages/refund-policy/edit-refund-policy/:id',
        //   element: <UpdateRefundPolicy />
        // },

      ]
    },
    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        { path: '/', element: <Navigate to="/dashboard/app" /> },
        { path: 'login', element: <Login /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" /> }
      ]
    },
    { path: '*', element: <Navigate to="/404" replace /> }
  ]);
}
